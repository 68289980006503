<template>
  <section class="sender-selector">
    <div class="sender-selector__options" v-if="showOptions">
      <div
        class="option con-vs-card withHover"
        :class="{ active: active === 1 }"
        @click="send()"
      >
        <vs-icon icon="icon-send" iconPack="feather" />
        <p class="text__big">
          {{ lang.audiences.add.wizard.audienceSend.send[languageSelected] }}
        </p>
      </div>
      <!-- <div
        class="option con-vs-card withHover"
        :class="{ active: active === 2 }"
        @click="quickSend()"
      >
        <vs-icon icon="icon-zap" iconPack="feather" />
        <p class="text__big">
          {{
            lang.audiences.add.wizard.audienceSend.quickSend[languageSelected]
          }}
        </p>
      </div> -->
      <div
        class="option con-vs-card withHover"
        :class="{ active: active === 3 }"
        @click="selectScheduledSend()"
      >
        <vs-icon icon="icon-clock" iconPack="feather" />
        <p class="text__big">
          {{
            lang.audiences.add.wizard.audienceSend.scheduledSend.title[
              languageSelected
            ]
          }}
        </p>
      </div>
    </div>

    <!-- <div v-if="active === 2">
      <h2 class="subtitle">
        {{ lang.audiences.add.wizard.audienceSend.quickSend[languageSelected] }}
      </h2>
      <vs-input type="text" placeholder="+5989..." v-model="quickNumber" />
      <vs-button
        type="flat"
        class="float-right"
        icon-pack="feather"
        icon="icon-x"
        color="danger"
        @click="cancel"
      >
        {{ lang.audiences.add.wizard.cancel[languageSelected] }}
      </vs-button>
    </div> -->
    <div v-if="active === 3">
      <h2 class="subtitle">
        {{
          lang.audiences.add.wizard.audienceSend.scheduledSend.title[
            languageSelected
          ]
        }}
      </h2>

      <label for="datetime">{{
        lang.audiences.add.wizard.audienceSend.scheduledSend.datetime[
          languageSelected
        ]
      }}</label>
      <datetime
        v-model="date"
        type="datetime"
        input-class="vs-inputx vs-input--input normal"
        id="datetime"
        class="theme-orange"
        :phrases="{
          ok: 'Ok',
          cancel: lang.audiences.add.wizard.cancel[languageSelected]
        }"
        :min-datetime="minDatetime"
        :auto="true"
        :value-zone="valueZone"
      ></datetime>

      <TimeZoneSelector v-model="timeZone" :showLabel="true" class="mt-4" />

      <div class="mt-10">
        <vs-button
          class="float-right"
          icon-pack="feather"
          icon="icon-send"
          color="success"
          :disabled="!date"
          @click="scheduledSend"
        >
          {{ lang.audiences.add.wizard.audienceSend.send[languageSelected] }}
        </vs-button>

        <vs-button
          type="flat"
          class="float-right"
          icon-pack="feather"
          icon="icon-x"
          color="danger"
          @click="cancel"
        >
          {{ lang.audiences.add.wizard.cancel[languageSelected] }}
        </vs-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Datetime } from 'vue-datetime'
// for datetime language
import { Settings } from 'luxon'
import 'vue-datetime/dist/vue-datetime.css'

import Helper from '../../../../../store/Helper'

export default {
  name: 'SenderSelector',
  // props: {
  //   active: {
  //     type: Number,
  //     default: null
  //   }
  // },
  data() {
    return {
      showOptions: true,
      active: null,
      quickNumber: null,
      date: null,
      minDatetime: new Date().toISOString(),
      timeZone: Helper.getBrowserTimeZone()
    }
  },
  components: {
    datetime: Datetime,
    TimeZoneSelector: () =>
      import('../../../../../components/TimeZoneSelector.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    valueZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  },
  methods: {
    // ...mapActions('audiences', ['saveAudience', 'savePush']),
    ...mapMutations('audiences', ['SET_PUSH_SCHEDULE']),
    send() {
      this.toggleActive(1)
      this.$emit('onSend')
    },
    // quickSend() {
    //   this.showOptions = false
    //   this.toggleActive(2)
    // },
    selectScheduledSend() {
      this.toggleActive(3)
      this.showOptions = false
    },
    scheduledSend() {
      // this is the expected datetime, be careful because it is not in ISO format
      const formattedDate = this.date.slice(0, 23).concat('Z')
      this.SET_PUSH_SCHEDULE({
        schedule: formattedDate,
        timeZone: this.timeZone.text
      })
      this.$emit('onSend')
    },
    toggleActive(active) {
      this.active = active || null
    },
    cancel() {
      this.toggleActive()
      this.date = null
      this.showOptions = true
    },
    setDatetimePickerLang() {
      Settings.defaultLocale = this.languageSelected
    }
  },
  watch: {
    '$store.state.languageSelected'() {
      this.setDatetimePickerLang()
    }
  },
  mounted() {
    this.setDatetimePickerLang()
  }
}
</script>

<style lang="scss">
.sender-selector {
  .sender-selector__options {
    display: flex;
    flex-direction: row;

    .option {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      min-width: 180px;
      width: 256px;

      background: white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      padding: 20px;

      &:not(:first-of-type) {
        margin-left: 20px;
      }

      &.con-vs-card {
        min-height: 164px;
      }

      // &.active {
      &:hover {
        background: rgba(var(--vs-primary), 1) !important;
        color: white;
        .feather {
          color: white;
        }
      }

      .feather {
        font-size: 40px;
      }

      .text__big {
        padding-top: 10px;
        font-size: 1.2rem;
        text-align: center;
      }

      #excel-input {
        display: none;
      }
    }
  }

  .subtitle {
    font-size: 1.2rem;
    font-weight: normal;
    color: #afafaf;
    padding-bottom: 1rem;
    text-align: center;
  }

  .theme-orange .vdatetime-popup__header,
  .theme-orange .vdatetime-calendar__month__day--selected > span > span,
  .theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
    background: rgba(var(--vs-primary), 1);
  }

  .theme-orange .vdatetime-year-picker__item--selected,
  .theme-orange .vdatetime-time-picker__item--selected,
  .theme-orange .vdatetime-popup__actions__button {
    color: rgba(var(--vs-primary), 1);
  }

  .con-select {
    width: auto;
  }
}
</style>
